<template>
  <v-dialog :value="value" width="90%" persistent @input="$emit('input', false)">
    <v-card class="dialog-content">
      <v-card-text>
        <image-cropper ref="imageCropper" v-model="croppable" :aspect-ratio="aspectRatio" :mb-size-limit="mbSizeLimit" />
      </v-card-text>

      <mf-action-buttons
        class="mr-2"
        :primary-button="{
          text: 'Salvar',
          action: save,
          isVisible: true,
          isLoading: saving,
          isDisabled: !croppable
        }"
        :extra-button="{
          text: 'Cancelar',
          action: cancel,
          isVisible: true
        }"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import path from 'path'
import crypto from 'crypto'
export default {
  components: {
    ImageCropper: () => import('../molecules/ImageCropper.vue')
  },
  props: {
    saveCallback: Function,
    value: Boolean,
    aspectRatio: {
      type: Number,
      required: true
    },
    mbSizeLimit: {
      type: Number,
      required: false
    }
  },
  data: () => ({
    saving: false,
    croppable: false,
    oldName: ''
  }),
  watch: {
    value(event) {
      if (!event) {
        this.$destroy()
      }
    }
  },
  methods: {
    async save() {
      this.saving = true
      const cropImage = this.$refs.imageCropper.cropImage()
      const fileName = this.renameFileWithRandomName(this.$refs.imageCropper.getFileName())
      let success = false
      try {
        this.$emit('save-image', { file: cropImage, fileName: fileName })
      } catch (e) {
        this.$alert({
          alert_message: 'Erro ao salvar a imagem',
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      }
      this.saving = false
      if (success) {
        this.$emit('input', false)
      }
    },
    cancel() {
      this.$emit('input', false)
    },
    getFileType(fileName) {
      const extension = path.extname(fileName)
      return extension
    },
    renameFileWithRandomName(fileName) {
      const extension = this.getFileType(fileName)
      const randomFileName = this.generateRandomFileName()
      const newFileName = randomFileName + extension
      return newFileName
    },
    generateRandomFileName() {
      const randomBytes = crypto.randomBytes(16)
      return randomBytes.toString('hex')
    }
  }
}
</script>

<style lang="scss">
.dialog-content {
  padding-top: 10px;
}
</style>
